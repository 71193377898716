(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
if ($('#dashboard').length) {

	console.log('dashboard.js');



	$(document).on('click', '.users-icon', function (e) {
		e.stopImmediatePropagation();
		//	Get siteid
		siteid = $(this).data('siteid');
		console.log('siteid: ' + siteid)

		promiseAjaxGet(window.confUrl + '/lib/site-info/?id=' + siteid).then(function (resp) {
			siteData = resp;
			console.log(resp);
			console.log(resp.site.name);
			console.log(resp.users[0].username)
			$('#modal-users .modal-title').html(resp.site.name);
			//	Modal content
			$('#modal-users .modal-body').html('');
			for (i = 0; i < resp.users.length; i++) {
				template = $('.template-users');
				$(template).find('.username').html(resp.users[i].username);
				$(template).find('.userrole').html(resp.users[i].userRole);

				$(template).find('.userdetails').attr('data-userid', resp.users[i].userID);
				console.log($(template).html())
				$('#modal-users .modal-body').append($(template).html());
				//	modalContent += $(template).html();
			}
			//	modalContent += '</div>';

			//	$('#modal-users .modal-body').append(modalContent);
			//$('#modal-users .modal-body').append(template);

			$('#modal-users').modal('show');
		});
	});

	$(document).on('click', '#modal-users .userdetails', function (e) {
		e.stopImmediatePropagation();
		//	Previous Modal content
		previousModalBody = $('#modal-users .modal-body').html();
		userid = $(this).data('userid');
		console.log(userid);
		for (i = 0; i < siteData.users.length; i++) {
			if (siteData.users[i].userID == userid) {
				$('#modal-users .modal-body').html('');
				template = $('.template-user');
				$(template).find('#f_username').attr('value', siteData.users[i].username);
				$(template).find('#f_company').attr('value', siteData.users[i].userco);
				$(template).find('#f_eamil').attr('value', siteData.users[i].useremail);
				$('#modal-users .modal-body').append($(template).html());
				break;
			}
		}

		console.log(previousModalBody);
		console.log(siteData);

	});

}
},{}],2:[function(require,module,exports){
if ($('#form-forgot-password').length) {

	console.log('form-forgot-password.js');

	// Background image
	randombg('form-forgot-password');

	//	Auto-fill
	setTimeout(function () {
		$('input:-webkit-autofill').each(function () {
			$(this).addClass('has-value');
		});
	}, 100);

	$(document).on('focus', '#form-forgot-password input', function (e) {
		e.stopImmediatePropagation();
		$('#form-forgot-password .alert').html('');
		$('#form-forgot-password .alert').addClass('hidden');
		$(this).addClass('has-value');
	});

	$(document).on('blur', '#form-forgot-password input', function (e) {
		e.stopImmediatePropagation();
		if ($(this).val() == '') $(this).removeClass('has-value');
	});


	$(document).on('click', '#f_forgot_password_submit', function (e) {
		e.stopImmediatePropagation();

		if ($('#f_email').val() != '') {
			fObj = $('#f_forgot_password').serializeArray();

			console.log(fObj);
			promiseAjaxPostData(window.confUrl + '/lib/forgot-password/', fObj).then(function (resp) {
				console.log(resp);
				if (resp == false) {
					$('#form-forgot-password .alert-danger').html('The email you entered is incorrect');
					$('#form-forgot-password .alert-danger').removeClass('hidden');
				} else {
					$('#form-forgot-password form').addClass('hidden');
					$('#form-forgot-password .alert-success').html('We have emailed your password reset link!');
					$('#form-forgot-password .alert-success').removeClass('hidden');
				}
			});

		} else {
			$('#form-forgot-password .alert-danger').html('The email you entered is incorrect');
			$('#form-forgot-password .alert-danger').removeClass('hidden');
		}
	});

}
},{}],3:[function(require,module,exports){
if ($('#form-login').length) {

	console.log('form-login.js');

	// Background image
	randombg('form-login');

	//	Auto-fill
	setTimeout(function () {
		$('input:-webkit-autofill').each(function () {
			$(this).addClass('has-value');
		});
	}, 100);

	$(document).on('focus', '#form-login input', function (e) {
		e.stopImmediatePropagation();
		$('#form-login .alert').html('');
		$('#form-login .alert').addClass('hidden');
		$(this).addClass('has-value');
	});

	$(document).on('blur', '#form-login input', function (e) {
		e.stopImmediatePropagation();
		if ($(this).val() == '') $(this).removeClass('has-value');
	});


	$(document).on('click', '#f_login_submit', function (e) {
		e.stopImmediatePropagation();

		if ($('#f_usr').val() != '' && $('#f_psw').val() != '') {
			fObj = $('#f_login').serializeArray();

			console.log(fObj);
			promiseAjaxPostData(window.confUrl + '/lib/check-login/', fObj).then(function (resp) {
				console.log(resp);
				if (resp == false) {
					$('#form-login .alert').html('The username or password you entered is incorrect');
					$('#form-login .alert').removeClass('hidden');
				} else {
					goToUrl = resp.url;
					console.log('go to: ' + goToUrl)
					/*
					if (goToUrl.indexOf('dashboard') > -1) {
						location.href = goToUrl;
					}
					*/
					location.href = goToUrl;

				}
			});

		} else {
			$('#form-login .alert').html('The username or password you entered is incorrect');
			$('#form-login .alert').removeClass('hidden');
		}
	});

}
},{}],4:[function(require,module,exports){
if ($('#form-reset-password').length) {

	console.log('form-reset-password.js');

	// Background image
	randombg('form-reset-password');

	//	Auto-fill
	setTimeout(function () {
		$('input:-webkit-autofill').each(function () {
			$(this).addClass('has-value');
		});
	}, 100);

	$(document).on('focus', '#form-reset-password input', function (e) {
		e.stopImmediatePropagation();
		$('#form-reset-password .alert').not('fixed').html('');
		$('#form-reset-password .alert').not('fixed').addClass('hidden');
		$(this).addClass('has-value');
	});

	$(document).on('blur', '#form-reset-password input', function (e) {
		e.stopImmediatePropagation();
		if ($(this).val() == '') $(this).removeClass('has-value');
	});


	$(document).on('click', '#f_reset_password_submit', function (e) {
		e.stopImmediatePropagation();
		if ($('#f_newpsw').val().length > 5) {
			if ($('#f_newpsw').val() == $('#f_confirmpsw').val()) {
				fObj = $('#f_reset_password').serializeArray();
				promiseAjaxPostData(window.confUrl + '/lib/reset-password/', fObj).then(function (resp) {
					console.log(resp);
					if (resp == false) {
						$('#form-reset-password .alert-danger').html('Your password is not valid');
						$('#form-reset-password .alert-danger').removeClass('hidden');
					} else {
						$('#form-reset-password form').addClass('hidden');
						$('#form-reset-password .alert-success').html('You can now log in with your new password');
						$('#form-reset-password .alert-success').removeClass('hidden');
					}
				});
			} else {
				$('#form-reset-password .alert-danger').html('Your password is not valid');
				$('#form-reset-password .alert-danger').removeClass('hidden');
			}
		} else {
			$('#form-reset-password .alert-danger').html('Your password must be at least 6 characters in length');
			$('#form-reset-password .alert-danger').removeClass('hidden');
		}
		/*
		if ($('#f_email').val() != '') {
			fObj = $('#f_reset_password').serializeArray();

			console.log(fObj);
			promiseAjaxPostData(window.confUrl + '/lib/reset-password/', fObj).then(function (resp) {
				console.log(resp);
				if (resp == false) {
					$('#form-reset-password .alert-danger').html('The email you entered is incorrect');
					$('#form-reset-password .alert-danger').removeClass('hidden');
				} else {
					$('#form-reset-password form').addClass('hidden');
					$('#form-reset-password .alert-success').html('We have emailed your password reset link!');
					$('#form-reset-password .alert-success').removeClass('hidden');
				}
			});

		} else {
			$('#form-reset-password .alert-danger').html('The email you entered is incorrect');
			$('#form-reset-password .alert-danger').removeClass('hidden');
		}
		*/
	});

}
},{}],5:[function(require,module,exports){
jQuery(function ($) {

	$ = jQuery;

	console.log('main.js');

	//	Config Url
	if ((window.location.href.indexOf("19000") > -1) || (window.location.href.indexOf("localhost") > -1) || (window.location.href.indexOf("19001") > -1)) {
		window.confUrl = 'http://localhost:19000/lcsthlm.co/SubDomains/woosh/dist';
	} else {
		window.confUrl = 'https://woosh.lcsthlm.co';
	}

	//	----------------------------
	// 	Promises
	//	----------------------------

	//	Get
	window.promiseAjaxGet = function (url) {
		console.log(url)
		return new Promise(function (resolve, reject) {
			$.ajax({
				url: url,
				method: 'get',
				dataType: 'json',
				success: function (resp) {
					resolve(resp);
				},
				error: function (XMLHttpRequest, textStatus, errorThrown) {
					reject(new Error("Network error"));
				}
			});
		});
	};

	//	Post
	window.promiseAjaxPostData = function (url, dataObj) {
		console.log('promiseAjaxPostData');
		return new Promise(function (resolve, reject) {
			$.ajax({
				url: url,
				type: 'POST',
				data: dataObj,
				dataType: 'json',
				success: function (resp) {
					resolve(resp);
				},
				error: function (XMLHttpRequest, textStatus, errorThrown) {
					reject(new Error("Network error"));
				}
			});
		});
	};

	// Image Background Randomized
	window.randombg = function ($id) {
		//	Set variables
		var min = 15;
		var now = new Date().getTime();
		var randomBg = Math.floor(Math.random() * 3) + 0;
		var wooshTime = localStorage.getItem('wooshTime');
		var wooshBg = localStorage.getItem('wooshBg');
		if (wooshTime == null) {
			localStorage.setItem('wooshTime', now);
			//	Start with the first bg by default
			localStorage.setItem('wooshBg', 0);
		} else {
			if (now - wooshTime > min * 60 * 1000) {
				localStorage.clear();
				localStorage.setItem('wooshTime', now);
				localStorage.setItem('wooshBg', randomBg);
			}
		}

		// var random = Math.floor(Math.random() * 3) + 0;
		var bgImage = [
			"url('https://lcsthlm.s3.eu-central-1.amazonaws.com/woosh/woosh-speed-2.jpg')",
			"url('https://lcsthlm.s3.eu-central-1.amazonaws.com/woosh/woosh-speed-1.jpg')",
			"url('https://lcsthlm.s3.eu-central-1.amazonaws.com/woosh/woosh-speed-3.jpg')"
		];
		document.getElementById($id).style.backgroundImage = bgImage[localStorage.getItem('wooshBg')];
	}

	var form_login = require('../js/form-login.js');
	var form_forgot_password = require('../js/form-forgot-password.js');
	var form_reset_password = require('../js/form-reset-password.js');
	var dashboard = require('../js/dashboard.js');
});
},{"../js/dashboard.js":1,"../js/form-forgot-password.js":2,"../js/form-login.js":3,"../js/form-reset-password.js":4}]},{},[5]);
